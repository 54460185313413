// @flow
import React from 'react';
import styles from './Icon.module.scss';

type Props = {
  icon: {
    svgPath?: string
  }
};

const Icon = ({ icon }: Props) => (
  <img className={styles['icon']} src={icon.svgPath}></img>
);

export default Icon;
