// @flow
const ICONS = {
 
  GITHUB: {
    svgPath: '/icons/github.svg'
  },
  EMAIL: {
    svgPath: '/icons/mail.svg'
  },
  XING: {
    svgPath: '/icons/xing.svg'
  },
  LINKEDIN:{
    svgPath: '/icons/linkedin.svg'
  },
  MEDIUM:{
    svgPath: '/icons/medium.svg'
  }
};

export default ICONS;
